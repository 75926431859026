import React from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import history from '../history'

//public
import Home from './Home'
import Ui from './Ui'
import Products from './Products'
import WhereToBuy from './WhereToBuy'
import Contact from './Contact'
import Product from './Product'
import Thankyou from './Thankyou'
import PageNotFound from './PageNotFound'
import PrivacyPolicy from './PrivacyPolicy'
import LegalNotice from './LegalNotice'
import Distributor from './Distributor'
import DistributorThankyou from './DistributorThankyou'
import Signin from './Signin'
import Dashboard from './dashboard/Dashboard'
import DashboardCategories from './dashboard/DashboardCategories'
import DashboardCategoryCreate from './dashboard/DashboardCategoryCreate'
import DashboardCategoryEdit from './dashboard/DashboardCategoryEdit'
import DashboardCategoriesBin from './dashboard/DashboardCategoriesBin'
import DashboardProducts from './dashboard/DashboardProducts'
import DashboardProductCreate from './dashboard/DashboardProductCreate'
import DashboardProductEdit from './dashboard/DashboardProductEdit'
import DashboardProductsBin from './dashboard/DashboardProductsBin'
import DashboardPartners from './dashboard/DashboardPartners'
import DashboardPartnerCreate from './dashboard/DashboardPartnerCreate'
import DashboardPartnerEdit from './dashboard/DashboardPartnerEdit'
import DashboardPartnersBin from './dashboard/DashboardPartnersBin'
import DashboardImages from './dashboard/DashboardImages'
import DashboardImageCreate from './dashboard/DashboardImageCreate'
import DashboardImageEdit from './dashboard/DashboardImageEdit'
import DashboardImagesBin from './dashboard/DashboardImagesBin'
import DashboardDocuments from './dashboard/DashboardDocuments'
import DashboardDocumentCreate from './dashboard/DashboardDocumentCreate'
import DashboardDocumentEdit from './dashboard/DashboardDocumentEdit'
import DashboardDocumentsBin from './dashboard/DashboardDocumentsBin'

class App extends React.Component {

    render(){
        return(
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/products" component={Products} />
                    <Route exact path="/where-to-buy" component={WhereToBuy} />
                    <Route exact path="/contact-us" component={Contact} />
                    <Route exact path="/contact-us/thankyou" component={Thankyou} />
                    <Route exact path="/ui" component={Ui} />
                    <Route exact path="/products/:cat/:slug" component={Product} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/legal-notice" component={LegalNotice} />
                    <Route exact path="/become-a-distributor" component={Distributor} />
                    <Route exact path="/become-a-distributor/thankyou" component={DistributorThankyou} />
                    <Route exact path="/login" component={Signin} />
                    <Route exact path="/signin" component={Signin} />
                    <Route exact path="/admin" component={Signin} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/categories" component={DashboardCategories} />
                    <Route exact path="/dashboard/products" component={DashboardProducts} />
                    <Route exact path="/dashboard/partners" component={DashboardPartners} />
                    <Route exact path="/dashboard/categories/create" component={DashboardCategoryCreate} />
                    <Route exact path="/dashboard/products/create" component={DashboardProductCreate} />
                    <Route exact path="/dashboard/partners/create" component={DashboardPartnerCreate} />
                    <Route exact path="/dashboard/categories/bin" component={DashboardCategoriesBin} />
                    <Route exact path="/dashboard/products/bin" component={DashboardProductsBin} />
                    <Route exact path="/dashboard/partners/bin" component={DashboardPartnersBin} />
                    <Route exact path="/dashboard/categories/:categoryId/edit" component={DashboardCategoryEdit} />
                    <Route exact path="/dashboard/products/:productId/edit" component={DashboardProductEdit} />
                    <Route exact path="/dashboard/partners/:partnerId/edit" component={DashboardPartnerEdit} />

                    <Route exact path="/dashboard/products/:productId/images" component={DashboardImages} />
                    <Route exact path="/dashboard/products/:productId/documents" component={DashboardDocuments} />
                    <Route exact path="/dashboard/products/:productId/images/create" component={DashboardImageCreate} />
                    <Route exact path="/dashboard/products/:productId/documents/create" component={DashboardDocumentCreate} />
                    <Route exact path="/dashboard/products/:productId/images/bin" component={DashboardImagesBin} />
                    <Route exact path="/dashboard/products/:productId/documents/bin" component={DashboardDocumentsBin} />
                    <Route exact path="/dashboard/products/:productId/images/:imageId/edit" component={DashboardImageEdit} />
                    <Route exact path="/dashboard/products/:productId/documents/:documentId/edit" component={DashboardDocumentEdit} />

                    <Route exact path="/dashboard/*" component={Dashboard} />
                    <Route exact path="/*" component={PageNotFound} />
                </Switch>
            </Router>
        )
    }
}

export default App