import React from 'react'
import Layout from './layout/Layout'
import video from '../assets/img/home/lacunza-hero.mp4'
import {Link} from 'react-router-dom'
import arrow from '../assets/img/arrow-icon.svg'
import SplitColumnsSection from './views/SplitColumnsSection'
import ContactSection from './views/ContactSection'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {
    fetchPublicCategories
} from '../actions'
import {connect} from 'react-redux'

import img1 from '../assets/img/home/lacunza-is-history@2x.jpg'
import img2 from '../assets/img/home/lacunza-is-commitment@2x.jpg'
import img3 from '../assets/img/home/lacunza-is-sustainability@2x.jpg'

class Home extends React.Component {

    componentDidMount(){
        this.props.fetchPublicCategories()
    }

    renderButtons = () => {
        return this.props.categories.map(({name, slug}) => {
            return <Link key={slug} to="/products" onClick={() => this.scrollToPosition(`#${slug}`)} className="_button _button-1">{name}</Link>
        })
    }

    scrollToPosition = (anchorTag, space) => {
        setTimeout(() => {
            const element = document.querySelector(anchorTag)
            if(element){
                let topPos = element.getBoundingClientRect().top + window.pageYOffset - 100
                if(space){
                    topPos = element.getBoundingClientRect().top + window.pageYOffset - space
                } 
                
                window.scrollTo({
                    top: topPos, // scroll so that the element is at the top of the view
                    behavior: 'smooth' // smooth scroll
                })
            }
        }, 300);
    }

    EmbedVideo = () => {
        return (
            <div  className="_embed-container" dangerouslySetInnerHTML={{ __html: `
              <video title="video" playsinline autoplay loop muted>
                <source src=${video} type="video/mp4" />
    
              Your browser does not support the video tag.
              </video>
            ` }} />
        )
     }

    render(){

        const data = [
            {
                img: img1,
                heading: (
                    <h2><span>Lacunza is…</span>
                    <br />HISTORY</h2>
                ),
                content: (
                    <>
                        <p>Our roots lie in the Sakana valley, in the Navarre region of Spain. We were born and raised here, and our business is marked by more than 50 years of work and a non-stop forward path.</p>
                        <p>For more than half a century, we have forged our business’ unique personality based on the sustainability of the environment, innovation, technology, design and customer orientation.</p>
                        <p>From the original iron foundry and subsequent cookstove and fireplace factory, LACUNZA has undergone a remarkable evolution and development. This is evidenced in our range of products, models, materials and energy sources, as well as the continued incorporation of quality, innovation and design into all our products.</p>
                    </>
                )
            },
            {
                img: img2,
                heading: (
                    <h2><span>Lacunza is…</span><br />
                        COMMITMENT</h2>
                ),
                content: (
                    <>
                        <p>At LACUNZA, we have set a benchmark for cookstoves, wood-burning stoves and fireplaces. We design, manufacture and market all our products.</p>
                        <p>Our greatest strength is our personnel and their commitment to our company. We strive to foster respect for the social, economic and environmental surroundings and through this, we give back to the environment.</p>
                    </>
                )
            },
            {
                img: img3,
                heading: (
                    <h2><span>Lacunza is…</span><br />
                        SUSTAINABILITY</h2>
                ),
                content: (
                    <>
                        <p>At LACUNZA, the importance of taking care of our forests is clear to us. Our manufacturing goal is for all our products to have the greatest energy efficiency with the smallest ecological footprint possible. We care for and replant forests that provide one of the greatest sources of heat and energy: wood.</p>
                    </>
                )
            }
        ]

        return(
            <>
            <Helmet>
                <title>Lacunza Natural Comfort | Australia</title>
                <meta name="description" content="At LACUNZA AU, we have set a benchmark for cookstoves, wood-burning stoves and fireplaces. We design, manufacture and market all our products." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <section id="_home-1" className='_section _section-hero'>
                    {this.EmbedVideo()}
                    <div className='_heading'>
                        <div className='_grid'>
                            <div className='_column'>
                                <h1>There’s a LACUNZA<br />
                                    that’s right for you</h1>
                                <h2 className='_heading-3'>#NaturalComfort</h2>
                            </div>
                        </div>
                    </div>
                    <div className='_buttons'>
                        <div className='_grid'>
                            <div className='_column'>
                                <div className='_group'>
                                    {this.renderButtons()}
                                    <button onClick={() => this.scrollToPosition("#_home-2", 65)} className='_scrollDown-button' aria-label="Scroll Down">
                                        <img src={arrow} alt="" width="20px" height="20px" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <SplitColumnsSection id="_home-2" data={data} />
                <ContactSection />
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.publicCategoriesState
    }
}

export default connect(mapStateToProps, {
    fetchPublicCategories
})(Home)