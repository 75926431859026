import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/products/products-banner.jpg'
import {Link} from 'react-router-dom'
import SectionHeading from './views/SectionHeading'
import ContactSection from './views/ContactSection'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {
    fetchPublicCategories
} from '../actions'
import {connect} from 'react-redux'
import {BASE_PATH} from '../paths'

class Products extends React.Component {

    componentDidMount(){
        this.props.fetchPublicCategories()
    }

    renderItems = (catSlug,products) => {
        return Object.values(products).map(({name,preview_image,heat_output_range,efficiency,slug}, index) => {
            return (
                <div className='_column' key={index}>
                    <div className='_card'>
                        {preview_image && <img src={BASE_PATH+preview_image} alt="" />}
                        <h4>{name}</h4>
                        <div className='_group'>
                            <p>
                                
                                {heat_output_range && (
                                    <>
                                        <span>Heat output range</span><br />
                                        {heat_output_range}
                                    </>
                                )}
                            </p>
                            <p>
                                
                                {efficiency && (
                                    <>
                                        <span>Efficiency</span><br />
                                        {efficiency}
                                    </>
                                )}
                            </p>
                        </div>
                        <div className='_button-group'>
                            <Link to={`/products/${catSlug}/${slug}`} className="_button">SEE MORE</Link>    
                        </div>
                    </div>    
                </div>
            )
        })
    }

    renderCategories = () => {
        return this.props.categories.map(({name, slug, products}, index) => {
            if(products.length === 0){
                return <></>
            }
            return (
                <section id={slug} className='_section _section-products' key={index}>
                    <div className='_grid'>
                        <div className='_column'>
                            <SectionHeading 
                                heading={name}
                            />
                        </div>
                    </div>
                    <div className='_grid _grid-3'>
                        {this.renderItems(slug, products)}
                    </div>
                </section>
            )
        })
    }

    render(){

        return(
            <>
            <Helmet>
                <title>Products | Lacunza Natural Comfort | Australia</title>
                <meta name="description" content="LACUNZA AU products use the most advanced technology, which makes everyday activities far easier. Such progress allows you to control your wood burning appliance and transform your house into a smart home where all you have to do is enjoy the fire." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="PRODUCTS"
                />
                {this.renderCategories()}

                {/* <section id="built-in" className='_section _section-products'>
                    <div className='_grid'>
                        <div className='_column'>
                            <SectionHeading 
                                heading="Built-in"
                            />
                        </div>
                    </div>
                    <div className='_grid _grid-3'>
                        {data && this.renderItems("built-in")}
                    </div>
                </section>
                <section id="freestanding" className='_section _section-products' style={{paddingTop: '0'}}>
                    <div className='_grid'>
                        <div className='_column'>
                            <SectionHeading 
                                heading="Freestanding"
                            />
                        </div>
                    </div>
                    <div className='_grid _grid-3'>
                        {data && this.renderItems("freestanding")}
                    </div>
                </section> */}
                <ContactSection />
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.publicCategoriesState
    }
}

export default connect(mapStateToProps, {
    fetchPublicCategories
})(Products)