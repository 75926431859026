import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/contact-us/contact-us-banner.jpg'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {Link} from 'react-router-dom'

class PageNotFound extends React.Component {

    render(){

        return(
            <>
            <Helmet>
                <title>404 Page Not Found | Lacunza Natural Comfort | Australia</title>
                <meta name="robots" content="noindex,nofollow" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="Whoops!"
                />
                <section className='_section _section-contact-us'>
                    <div className='_grid'>
                        <div className='_column' style={{textAlign: 'center'}}>
                            <h2>404 Page Not Found</h2>   
                            <p>The page you are looking for does not exist.</p> 
                            <Link to="/" className='_button _button-2' style={{margin: '30px auto 0 auto'}}>Go To Home Page</Link>
                        </div>    
                    </div>    
                </section>
            </Layout>
            </>
        )
    }
}

export default PageNotFound