import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/distributor-banner.jpg'
import Form from './forms/DistributorForm'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import {
    submitDistributorForm,
    resetForms
} from '../actions'
import history from '../history'

class Distributor extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    componentDidUpdate(){
        if(this.props.send.errors){
            setTimeout(() => {
                this.props.resetForms()
            }, 7000)
        }
    }

    onSubmit = formValues => {
        this.props.submitDistributorForm(formValues)
    }

    render(){

        this.props.send.success && history.push('/become-a-distributor/thankyou')

        return(
            <>
            <Helmet>
                <title>Become A Distributor | Lacunza Natural Comfort | Australia</title>
                <meta name="description" content="Become a Distributor. Are you interested in joining the Lacunza family of distributors in Australia? We are looking for enthusiastic and motivated partners that believe that quality, safety, and design are non-negotiable." />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="DISTRIBUTOR"
                />
                <section className='_section _section-contact-us'>
                    <div className='_grid'>
                        <div className='_column' style={{textAlign: 'center'}}>
                            <div className='_panel'>
                                <h2>Become a Distributor</h2>   
                                <p>Are you interested in joining the Lacunza family of distributors? We are looking for enthusiastic and motivated partners that believe that quality, safety, and design are non-negotiable. When working with Lacunza, you will have the opportunity to distribute our built-in and freestanding Wood Fireplaces and Kitchen Stoves. All of which have been designed and built in Spain by Lacunza for over 50 years. The Lacunza brand is renowned for innovation, high efficiency, and sustainability. Upgrade your showroom with world-class Lacunza Fireplaces and Kitchen Stoves. Please feel free to contact us by completing the form below.</p>
                            </div> 
                        </div>    
                    </div> 
                    {/* <div className='_grid'>
                        <div className='_column'>
                            <h2>Distributor Enquiry</h2>   
                            <p>Please fill the form below and we will reply shortly.</p> 
                        </div>    
                    </div>    */}
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_panel'>
                                <Form
                                    onSubmit={this.onSubmit} 
                                    errors={this.props.send.errors ? this.props.send.errors : null}
                                />   
                            </div>
                        </div>    
                    </div> 
                    <div className='_grid'>
                        <div className='_column' style={{textAlign: 'center'}}>
                            <h2>Or Email Us At</h2>   
                            <p><a href="mailto:info@lacunza.com.au">info@lacunza.com.au</a></p> 
                        </div>    
                    </div> 
                </section>
            </Layout>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        send: state.distributorState
    }
}

export default connect(mapStateToProps, {
    submitDistributorForm,
    resetForms
})(Distributor)