import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchImage,
    resetForms,
    updateImage,
    fetchProducts
} from '../../actions'
import Form from '../forms/ImageEditForm'

class DashboardImageEdit extends React.Component {

    componentDidMount(){
        this.props.fetchImage(this.props.token, this.props.match.params.productId, this.props.match.params.imageId)
        this.props.fetchProducts(this.props.token)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updateImage(this.props.token, this.props.match.params.productId, this.props.match.params.imageId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to={`/dashboard/products/${this.props.match.params.productId}/images`} />
        }

        return(
            <DashboardLayout 
                heading="Edit Image" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to={`/dashboard/products`}>Products</Link> / {this.props.match.params.productId} / <Link to={`/dashboard/products/${this.props.match.params.productId}/images`}>Images</Link> / {this.props.match.params.imageId} / Edit
                </>)}
            >

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                        products={this.props.products ? this.props.products : null}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.imagesState[ownProps.match.params.productId] && state.imagesState[ownProps.match.params.productId][ownProps.match.params.imageId],
        data: state.imageUpdateState,
        products: Object.values(state.productsState)
    }
}

export default connect(mapStateToProps, {
    fetchImage,
    updateImage,
    resetForms,
    fetchProducts
})(DashboardImageEdit)