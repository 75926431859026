import React from 'react'

class SplitColumnsSection extends React.Component {

    renderItems = () => {
        return this.props.data.map(({img, heading, content}, index) => {
            return (
                <div className='_split-row' key={index}>
                    <div className='_split-column' style={{backgroundImage: `url(${img})`}}></div>
                    <div className='_split-column'>
                        <div className='_split-grid'>
                            <div>
                                {heading}
                                <div className='_content'>{content}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render(){

        return(
            <section id={this.props.id ? this.props.id : ''} className='_section _section-split-columns'>
                {this.props.data && this.renderItems()}
            </section>
        )
    }
}

export default SplitColumnsSection