import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input
} from './fields'
import FileInput from './FileInput'
import {BASE_PATH} from '../../paths'

class documentEditForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    renderSuccess = () => {
        return (
            <div className="_success-group">
                <div className="_success-message">{this.props.success}</div>
            </div>
        )
    }

    onSubmit = formValues => {
        const data = new FormData()
        formValues.title && data.append('title', formValues.title)
        formValues.temp_path && data.append('path', formValues.temp_path)
        formValues.order_number && data.append('order_number', formValues.order_number)
        this.props.onSubmit(data)
    }

    render(){

        return(
            <form className="_form _form-dashboard" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field 
                        type="text"
                        name="title" 
                        label="Title" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field type="file" name="path_temp" component={FileInput} label="Upload Document*" img={this.props.initialValues.path ? BASE_PATH+this.props.initialValues.path : null} />
                </div>

                <div className="_form-row">
                    <Field 
                        type="number"
                        name="order_number" 
                        label="Priority" 
                        component={Input} 
                    />
                </div>
                
                {this.props.errors && this.renderErrors()}
                {this.props.success && this.renderSuccess()}
                <div className="_form-row">
                    <button className="_button _button-2" >Update</button>
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}
    
    return errors
}

export default reduxForm({
    form: 'documentedit',
    validate
})(documentEditForm)