import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    resetForms,
    createPartner
} from '../../actions'
import Form from '../forms/PartnerCreateForm'

class DashboardPartnerCreate extends React.Component {

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.createPartner(this.props.token, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/partners" />
        }

        return(
            <DashboardLayout 
                heading="Create Partner" s
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/partners">Partner</Link> / Create
                </>)}
            >

                <Form 
                    onSubmit={this.onSubmit} 
                    errors={this.props.data.errors ? this.props.data.errors : null} 
                    success={this.props.data.success ? this.props.data.success : null}
                />
            </DashboardLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token,
        data: state.partnerCreateState,
    }
}

export default connect(mapStateToProps, {
    createPartner,
    resetForms
})(DashboardPartnerCreate)