import React from 'react'
import Layout from './layout/Layout'
import data from './productsData'
import ContactSection from './views/ContactSection'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import {
    fetchPublicProduct
} from '../actions'
import {connect} from 'react-redux'
import {BASE_PATH} from '../paths'

class Product extends React.Component {

    componentDidMount(){
        this.props.fetchPublicProduct(this.props.match.params.slug)
    }

    EmbedVideo = video => {
        return (
            <div  className="_embed-container" dangerouslySetInnerHTML={{ __html: `
              <video title="video" playsinline autoplay loop controls muted>
                <source src=${BASE_PATH+video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ` }} />
        )
    }

    renderGallery = (images) => {
        return images.map(({path, alt, caption}, index) => {
            return (
                <div className='_gallery-item' key={index}>
                    <img src={BASE_PATH+path} alt={alt ? alt : ""} />
                    {caption && <div className='_caption'>{caption}</div>}
                </div>
            )
        })
    }

    renderDownloadButtons = documents => {
        return documents.map(({path,title}, index) => {
            return (
                <a key={index} href={BASE_PATH+path} download={`${title} Specs`} className="_button _button-3 _button-download-specs" target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z"/></svg>
                    &nbsp;{title ? title : "DOWNLOAD SPECS"}
                </a>
            )
        })
    }

    render(){

        if(!this.props.product){
            return <></>
        }

        const {banner_image,category,characteristics,images,name,video,documents,meta_description, meta_keywords} = this.props.product

        return(
            <>
            <Helmet>
                <title>{name} {category.name + ' | '} Lacunza Natural Comfort | Australia</title>
                <meta name="description" content={meta_description ? meta_description : 'LACUNZA AU products use the most advanced technology, which makes everyday activities far easier. Such progress allows you to control your wood burning appliance and transform your house into a smart home where all you have to do is enjoy the fire.'} />
                {meta_keywords && <meta name="keywords" content={meta_keywords}></meta>}
                <link rel="canonical" href={window.location.href} />
                
            </Helmet>
            <ScrollToTop />
            <Layout>
                {
                    banner_image && <section className='_section _section-product-banner' style={{
                        backgroundImage: `url(${BASE_PATH+banner_image})`
                    }} />
                }
                
                <section className='_section _section-product-content'>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_group-heading'>
                                <h4 style={{textTransform: 'uppercase'}}>{category.name}</h4>   
                                <div className='_hr' /> 
                            </div>
                            
                            <h1>{name}</h1>
                        </div>    
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            {video && this.EmbedVideo(video)}
                        </div>    
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            {images.length > 0 && (
                                <div className='_gallery'>
                                    {this.renderGallery(images)}
                                </div>
                            )}
                        </div>    
                    </div>
                    <div className='_grid'>
                        <div className='_column'>
                            <div className='_inner'>
                                <h2 className='_heading-characters'>CHARACTERISTICS</h2>
                                <div className='_panel _char' dangerouslySetInnerHTML={{__html: characteristics}} />
                                <div className='_button-group'>
                                    {documents.length > 0 && this.renderDownloadButtons(documents)}
                                </div>
                            </div>
                        </div>    
                    </div>
                </section>
                <ContactSection />
            </Layout>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        product: state.publicProductState[ownProps.match.params.slug]
    }
}

export default connect(mapStateToProps, {
    fetchPublicProduct
})(Product)