import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input,
    Select,
    TextArea
} from './fields'
import FileInput from './FileInput'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {BASE_PATH} from '../../paths'

class productEditForm extends React.Component {

    state = {
        characteristics: this.props.initialValues.characteristics ? this.props.initialValues.characteristics : ""
    }

    renderOptions = () => {
        return this.props.categories.map(({id, name}) => {
            return <option value={id} key={id}>{name}</option>
        })
    }

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    renderSuccess = () => {
        return (
            <div className="_success-group">
                <div className="_success-message">{this.props.success}</div>
            </div>
        )
    }

    onSubmit = formValues => {
        const data = new FormData()
        formValues.name && data.append('name', formValues.name)
        formValues.category_id && data.append('category_id', formValues.category_id)
        formValues.preview_image_temp && data.append('preview_image', formValues.preview_image_temp)
        formValues.banner_image_temp && data.append('banner_image', formValues.banner_image_temp)
        formValues.video_temp && data.append('video', formValues.video_temp)
        this.state.characteristics && data.append('characteristics', this.state.characteristics)
        formValues.meta_description && data.append('meta_description', formValues.meta_description)
        formValues.meta_keywords && data.append('meta_keywords', formValues.meta_keywords)
        formValues.heat_output_range && data.append('heat_output_range', formValues.heat_output_range)
        formValues.efficiency && data.append('efficiency', formValues.efficiency)
        formValues.order_number && data.append('order_number', formValues.order_number)
        this.props.onSubmit(data)
    }

    render(){

        return(
            <form className="_form _form-dashboard" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field 
                        type="text"
                        name="name" 
                        label="Name" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        name="category_id" 
                        label="Category" 
                        component={Select} 
                    >
                        <option value="">Please select a category</option>
                        {this.renderOptions()}
                    </Field>
                </div>

                <div className="_form-row">
                    <Field type="file" name="preview_image_temp" component={FileInput} label="Upload Preview Image*" img={this.props.initialValues.preview_image ? BASE_PATH+this.props.initialValues.preview_image : null} />
                </div>

                <div className="_form-row">
                    <Field type="file" name="banner_image_temp" component={FileInput} label="Upload Banner Image*" img={this.props.initialValues.banner_image ? BASE_PATH+this.props.initialValues.banner_image : null} />
                </div>

                <div className="_form-row">
                    <Field type="file" name="video_temp" component={FileInput} label="Upload MP4 Video*" video={this.props.initialValues.video ? BASE_PATH+this.props.initialValues.video : null} />
                </div>

                <div className="_form-row">
                    <div className="_form-group">
                        <label>Characteristics*</label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={this.state.characteristics}
                            onChange={ ( e, editor ) => {
                                const characteristics = editor.getData();
                                this.setState({ characteristics })
                            } }
                        />
                    </div>
                </div>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="heat_output_range" 
                        label="Heat Output Range" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        type="text"
                        name="efficiency" 
                        label="Efficiency" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        name="meta_description" 
                        label="Meta Description" 
                        component={TextArea} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        name="meta_keywords" 
                        label="Meta Keywords" 
                        component={TextArea} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        type="number"
                        name="order_number" 
                        label="Priority" 
                        component={Input} 
                    />
                </div>
                
                {this.props.errors && this.renderErrors()}
                {this.props.success && this.renderSuccess()}
                <div className="_form-row">
                    <button className="_button _button-2" >Update</button>
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}

    if(!formValues.name){
        errors.name = "you must enter a name"
    }

    if(!formValues.category_id){
        errors.category_id = "you must select a category"
    }
    
    return errors
}

export default reduxForm({
    form: 'productedit',
    validate
})(productEditForm)