import React from 'react'

class SectionHeading extends React.Component {

    render(){

        return(
            <div className='_group _group-section-heading'>
                <div className='_hr' />
                {this.props.heading && <h2>{this.props.heading}</h2>}
                <div className='_hr' />
            </div>
        )
    }
}

export default SectionHeading