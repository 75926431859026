import admin from '../apis/admin'

import {
    RESET_FORMS,
    SEND_CONTACT_MESSAGE,
    SEND_CONTACT_MESSAGE_FAILED,
    SIGNIN,
    SIGNIN_FAILED,
    SIGNOUT,
    FETCH_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS,
    UPDATE_ACCOUNT_DETAILS_FAILES,
    FETCH_USERS,
    CREATE_USER,
    CREATE_USER_FAILED,
    FETCH_USER,
    UPDATE_USER,
    UPDATE_USER_FAILED,
    DELETE_USER,
    FETCH_CATEGORIES,
    CREATE_CATEGORY,
    CREATE_CATEGORY_FAILED,
    FETCH_CATEGORY,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_FAILED,
    DELETE_CATEGORY,
    RESTORE_CATEGORY,
    FETCH_CATEGORIES_BIN,
    FORCE_DELETE_CATEGORY,
    FETCH_PRODUCTS,
    CREATE_PRODUCT,
    CREATE_PRODUCT_FAILED,
    FETCH_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_FAILED,
    DELETE_PRODUCT,
    RESTORE_PRODUCT,
    FETCH_PRODUCTS_BIN,
    FORCE_DELETE_PRODUCT,
    FETCH_IMAGES,
    CREATE_IMAGE,
    CREATE_IMAGE_FAILED,
    FETCH_IMAGE,
    UPDATE_IMAGE,
    UPDATE_IMAGE_FAILED,
    DELETE_IMAGE,
    RESTORE_IMAGE,
    FETCH_IMAGES_BIN,
    FORCE_DELETE_IMAGE,
    FETCH_DOCUMENTS,
    CREATE_DOCUMENT,
    CREATE_DOCUMENT_FAILED,
    FETCH_DOCUMENT,
    UPDATE_DOCUMENT,
    UPDATE_DOCUMENT_FAILED,
    DELETE_DOCUMENT,
    RESTORE_DOCUMENT,
    FETCH_DOCUMENTS_BIN,
    FORCE_DELETE_DOCUMENT,
    FETCH_PARTNERS,
    CREATE_PARTNER,
    CREATE_PARTNER_FAILED,
    FETCH_PARTNER,
    UPDATE_PARTNER,
    UPDATE_PARTNER_FAILED,
    DELETE_PARTNER,
    RESTORE_PARTNER,
    FETCH_PARTNERS_BIN,
    FORCE_DELETE_PARTNER,
    FETCH_PUBLIC_CATEGORIES,
    FETCH_PUBLIC_PRODUCT,
    FETCH_PUBLIC_PARTNERS,
    SUBMIT_DISTRIBUTOR_FORM,
    SUBMIT_DISTRIBUTOR_FORM_FAILED,
    FETCH_PUBLIC_PRODUCT_NAMES
} from './types'

export const resetForms = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_FORMS
        })
    }
}

export const sendContactMessage = formValues => async dispatch => {
    try {

        const response = await admin.post('contact', formValues)

        dispatch({
            type: SEND_CONTACT_MESSAGE,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SEND_CONTACT_MESSAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signIn = formValues => async dispatch => {
    try {

        const response = await admin.post('login', formValues)

        dispatch({
            type: SIGNIN,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SIGNIN_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const signOut = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/logout', {}, config)

        console.log('signed out')

        dispatch({
            type: SIGNOUT
        })
        
    } catch (error) {

        console.log('sign out failed')
        
        dispatch({
            type: SIGNOUT
        })
    }
}

export const fetchAccountDetails = token => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/my-account', config)

        dispatch({
            type: FETCH_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch account details failed')
    }
}

export const updateAccountDetails = (token, formValues) => async dispatch => {
    try {

        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/my-account/update', formValues, config)

        dispatch({
            type: UPDATE_ACCOUNT_DETAILS,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_ACCOUNT_DETAILS_FAILES,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUsers = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/users', config)

        dispatch({
            type: FETCH_USERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching users failed')
    }
}

export const createUser = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/users/create', formValues, config)

        dispatch({
            type: CREATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/users/'+id, config)

        dispatch({
            type: FETCH_USER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetching user failed')
    }
}


export const updateUser = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/users/'+id+'/update', formValues, config)

        dispatch({
            type: UPDATE_USER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const deleteUser = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/users/'+id+'/delete', {}, config)

        dispatch({
            type: DELETE_USER,
            payload: id
        })
        
    } catch (error) {
        console.log('delete user failed')
    }
}

//categories

export const fetchCategories = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/categories', config)

        dispatch({
            type: FETCH_CATEGORIES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch categories failed')
    }
}

export const createCategory = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/categories/create', formValues, config)

        dispatch({
            type: CREATE_CATEGORY,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_CATEGORY_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchCategory = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/categories/' + id, config)

        dispatch({
            type: FETCH_CATEGORY,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch category failed')
    }
}

export const updateCategory = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/categories/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_CATEGORY,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_CATEGORY_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteCategory = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/categories/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_CATEGORY,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete category failed')        
    }
}

export const fetchCategoriesBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/categories/bin', config)

        dispatch({
            type: FETCH_CATEGORIES_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch categories bin failed')
    }
}

export const restoreCategory = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/categories/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_CATEGORY,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Category restore failed')
    }
}

export const forceDeleteCategory = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/categories/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_CATEGORY,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete category failed')        
    }
}

//products

export const fetchProducts = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/products', config)

        dispatch({
            type: FETCH_PRODUCTS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch products failed')
    }
}

export const createProduct = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/create', formValues, config)

        dispatch({
            type: CREATE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_PRODUCT_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/products/' + id, config)

        dispatch({
            type: FETCH_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch product failed')
    }
}

export const updateProduct = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/products/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_PRODUCT,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete product failed')        
    }
}

export const fetchProductsBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/products/bin', config)

        dispatch({
            type: FETCH_PRODUCTS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch products bin failed')
    }
}

export const restoreProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/products/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_PRODUCT,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Product restore failed')
    }
}

export const forceDeleteProduct = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/products/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_PRODUCT,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete product failed')        
    }
}

//images

export const fetchImages = (token, productId) => async dispatch => {

    
    
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`admin/products/${productId}/images`, config)

        dispatch({
            type: FETCH_IMAGES,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('fetch images failed')
    }
}

export const createImage = (token, productId, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post(`admin/products/${productId}/images/create`, formValues, config)

        dispatch({
            type: CREATE_IMAGE,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_IMAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchImage = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`admin/products/${productId}/images/${id}`, config)

        dispatch({
            type: FETCH_IMAGE,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('Fetch image failed')
    }
}

export const updateImage = (token,  productId, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post(`admin/products/${productId}/images/${id}/update`, formValues, config)

        dispatch({
            type: UPDATE_IMAGE,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_IMAGE_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteImage = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post(`admin/products/${productId}/images/${id}/delete`, {}, config)

        dispatch({
            type: DELETE_IMAGE,
            payload: id,
            productId,
            id
        })
        
    } catch (error) {
        console.log('Delete image failed')        
    }
}

export const fetchImagesBin = (token, productId) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`admin/products/${productId}/images/bin`, config)

        dispatch({
            type: FETCH_IMAGES_BIN,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('fetch images bin failed')
    }
}

export const restoreImage = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post(`admin/products/${productId}/images/${id}/restore`, {}, config)

        dispatch({
            type: RESTORE_IMAGE,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('Image restore failed')
    }
}

export const forceDeleteImage = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post(`admin/products/${productId}/images/${id}/delete`, {}, config)

        dispatch({
            type: FORCE_DELETE_IMAGE,
            payload: id,
            productId,
            id
        })
        
    } catch (error) {
        console.log('Force delete image failed')        
    }
}

//documents

export const fetchDocuments = (token, productId) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`admin/products/${productId}/documents`, config)

        dispatch({
            type: FETCH_DOCUMENTS,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('fetch documents failed')
    }
}

export const createDocument = (token, productId, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post(`admin/products/${productId}/documents/create`, formValues, config)

        dispatch({
            type: CREATE_DOCUMENT,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_DOCUMENT_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchDocument = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`admin/products/${productId}/documents/${id}`, config)

        dispatch({
            type: FETCH_DOCUMENT,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('Fetch document failed')
    }
}

export const updateDocument = (token,  productId, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post(`admin/products/${productId}/documents/${id}/update`, formValues, config)

        dispatch({
            type: UPDATE_DOCUMENT,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_DOCUMENT_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deleteDocument = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post(`admin/products/${productId}/documents/${id}/delete`, {}, config)

        dispatch({
            type: DELETE_DOCUMENT,
            payload: id,
            productId,
            id
        })
        
    } catch (error) {
        console.log('Delete document failed')        
    }
}

export const fetchDocumentsBin = (token, productId) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get(`admin/products/${productId}/documents/bin`, config)

        dispatch({
            type: FETCH_DOCUMENTS_BIN,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('fetch documents bin failed')
    }
}

export const restoreDocument = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post(`admin/products/${productId}/documents/${id}/restore`, {}, config)

        dispatch({
            type: RESTORE_DOCUMENT,
            payload: response.data,
            productId
        })
        
    } catch (error) {
        console.log('Document restore failed')
    }
}

export const forceDeleteDocument = (token, productId, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post(`admin/products/${productId}/documents/${id}/delete`, {}, config)

        dispatch({
            type: FORCE_DELETE_DOCUMENT,
            payload: id,
            productId,
            id
        })
        
    } catch (error) {
        console.log('Force delete document failed')        
    }
}

//partners

export const fetchPartners = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/partners', config)

        dispatch({
            type: FETCH_PARTNERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch partners failed')
    }
}

export const createPartner = (token, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/partners/create', formValues, config)

        dispatch({
            type: CREATE_PARTNER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: CREATE_PARTNER_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}

export const fetchPartner = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/partners/' + id, config)

        dispatch({
            type: FETCH_PARTNER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch partner failed')
    }
}

export const updatePartner = (token, id, formValues) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/partners/' + id + '/update', formValues, config)

        dispatch({
            type: UPDATE_PARTNER,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: UPDATE_PARTNER_FAILED,
            payload: error.response ? error.response.data : null
        })
        
    }
}

export const deletePartner = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/partners/' + id + '/delete', {}, config)

        dispatch({
            type: DELETE_PARTNER,
            payload: id
        })
        
    } catch (error) {
        console.log('Delete partner failed')        
    }
}

export const fetchPartnersBin = token => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.get('admin/partners/bin', config)

        dispatch({
            type: FETCH_PARTNERS_BIN,
            payload: response.data
        })
        
    } catch (error) {
        console.log('fetch partners bin failed')
    }
}

export const restorePartner = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        const response = await admin.post('admin/partners/'+id+'/restore', {}, config)

        dispatch({
            type: RESTORE_PARTNER,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Partner restore failed')
    }
}

export const forceDeletePartner = (token, id) => async dispatch => {
    try {
        const config = { 
            headers: { 
                Authorization: `Bearer ${token}` 
            } 
        };
        
        await admin.post('admin/partners/' + id + '/delete', {}, config)

        dispatch({
            type: FORCE_DELETE_PARTNER,
            payload: id
        })
        
    } catch (error) {
        console.log('Force delete partner failed')        
    }
}

export const fetchPublicCategories = () => async dispatch => {
    try {
        
        const response = await admin.get('categories')

        dispatch({
            type: FETCH_PUBLIC_CATEGORIES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch categories failed')        
    }
}

export const fetchPublicProduct = slug => async dispatch => {
    try {
        
        const response = await admin.get('products/' + slug)

        dispatch({
            type: FETCH_PUBLIC_PRODUCT,
            payload: response.data,
            slug
        })
        
    } catch (error) {
        console.log('Fetch product failed')        
    }
}

export const fetchPublicPartners = () => async dispatch => {
    try {
        
        const response = await admin.get('partners')

        dispatch({
            type: FETCH_PUBLIC_PARTNERS,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch partners failed')        
    }
}

export const fetchPublicProductNames = () => async dispatch => {
    try {
        
        const response = await admin.get('products/list')

        dispatch({
            type: FETCH_PUBLIC_PRODUCT_NAMES,
            payload: response.data
        })
        
    } catch (error) {
        console.log('Fetch partners failed')        
    }
}

export const submitDistributorForm = formValues => async dispatch => {
    try {

        const response = await admin.post('distributor/create', formValues)

        dispatch({
            type: SUBMIT_DISTRIBUTOR_FORM,
            payload: response.data
        })
        
    } catch (error) {
        dispatch({
            type: SUBMIT_DISTRIBUTOR_FORM_FAILED,
            payload: error.response ? error.response.data : null
        })
    }
}