import React from 'react'
import { Link } from "react-router-dom"
import {connect} from 'react-redux'
import {
    signOut
} from '../../../actions'
import {
    Redirect
} from 'react-router-dom'
import MenuButton from './MenuButton'
import Logo from './Logo'
import AccountIcon from './AccountIcon'

class DashboardLayout extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            menuOpen: false
        }
        this.myRef = React.createRef()
        this.myRef2 = React.createRef()
    }

    componentDidMount(){
        this.toggleDropdown()
        this.toggleMenuItems()
    }

    toggleDropdown = () => {
        const nodeList = document.querySelectorAll("._dashboard-list li a")
        window.addEventListener("click", e => {
            for(let a = 0; a < nodeList.length; a++){
                if(this.myRef.current && this.myRef2.current){
                    if(!this.myRef.current.contains(e.target) && !this.myRef2.current.contains(e.target)){
                        const show = document.querySelectorAll("._dashboard-list li ._show")
                        for(let b = 0; b < show.length; b++){
                            show[b].classList.remove("_show")
                        }
                    }
                }                
            }
        })
        for(let i = 0; i < nodeList.length; i++){
            if(nodeList[i].nextElementSibling && nodeList[i].nextElementSibling.tagName === "UL"){
                nodeList[i].addEventListener("click", e => {
                    e.preventDefault()
                    nodeList[i].nextElementSibling.classList.toggle('_show')                   
                })
            }
        }
    }

    toggleMenuItems = () => {
        const nodeList = document.querySelectorAll("._dashboard-side-menu ._list li a")        
        for(let i = 0; i < nodeList.length; i++){
            if(nodeList[i].nextElementSibling){
                nodeList[i].classList.add("_arrow")
                nodeList[i].addEventListener("click", e => {
                    e.preventDefault()
                    if(nodeList[i].nextElementSibling.classList.contains('_show')){
                        nodeList[i].nextElementSibling.classList.remove('_show')
                        nodeList[i].classList.remove('_up')
                    } else {
                        this.closeDropdowns()
                        nodeList[i].nextElementSibling.classList.add('_show')
                        nodeList[i].classList.add('_up')
                    }                    
                })
            } 
        }
    }

    closeDropdowns = () => {
        const dropdowns = document.querySelectorAll("._dashboard-side-menu ._list li > ul")
        const arrow = document.querySelectorAll("._dashboard-side-menu ._list li ._arrow")
        for(let a = 0; a < dropdowns.length; a++){
            dropdowns[a].classList.remove('_show')
        }
        for(let b = 0; b < arrow.length; b++){
            arrow[b].classList.remove('_up')
        }
    }

    toggleMenu = () => {
        this.closeDropdowns()
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    onSignOut = e => {
        e.preventDefault()
        this.props.signOut(this.props.token)
    }
    
    render(){

        if(!this.props.token){
            return <Redirect to="/login" />
        }

        return(
            <>

                <div className="_dashboard-layout">
                    <header className='_dashboard-header'>
                        <div className='_item'>
                            <ul className='_dashboard-list'>
                                <li className={`_menu-icon ${this.state.menuOpen ? '_close' : ''}`}>
                                    <MenuButton onClick={this.toggleMenu} />
                                </li>
                                <li className='_logo-li'>
                                    <Logo />
                                </li>
                                <li className='_breadcrums'>
                                    <p>{this.props.breadcrumbs}</p>
                                </li>
                            </ul>
                        </div>
                        <div className='_item'>
                            <ul className='_dashboard-list'>
                                <li>
                                    <a ref={this.myRef2} href="/" className='_profile-toggle-button'>
                                        <AccountIcon fill="#242A2F" />
                                    </a>
                                    <ul ref={this.myRef}>
                                        <li>
                                            <Link to="/dashboard/my-account">My Account</Link>
                                        </li>
                                        <li>
                                            <a href="/" onClick={this.onSignOut}>Logout</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            
                        </div>
                    </header>
                    <nav className={`_dashboard-side-menu ${this.state.menuOpen ? '_show' : ''}`}>
                        <div className='_overlay' onClick={this.toggleMenu} />
                        <ul className='_list'>
                            <li>
                                <Link to="/dashboard">
                                    Dashboard
                                </Link>
                            </li>
                            <li>
                                <div className='_hr-dashboard' />
                            </li>
                            <li>
                                <Link to="/dashboard/categories">
                                    Categories
                                </Link>
                                <ul>
                                    <li>
                                        <Link to="/dashboard/categories">
                                            All Categories
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/categories/create">
                                            Create Categories
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/categories/bin">
                                            Categories Bin
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/dashboard/products">
                                    Products
                                </Link>
                                <ul>
                                    <li>
                                        <Link to="/dashboard/products">
                                            All Products
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/products/create">
                                            Create Products
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/products/bin">
                                        Products Bin
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/dashboard/partners">
                                    Partners
                                </Link>
                                <ul>
                                    <li>
                                        <Link to="/dashboard/partners">
                                            All Partners
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/partners/create">
                                            Create Partners
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/partners/bin">
                                            Partners Bin
                                        </Link>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <Link to="/dashboard/users">
                                    Administrators
                                </Link>
                                <ul>
                                    <li>
                                        <Link to="/dashboard/users">
                                            All Users
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/users/create">
                                            Create User
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/users/bin">
                                            Users Bin
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/dashboard/my-account">My Account</Link>
                            </li>
                            <li>
                                <div className='_hr-dashboard' />
                            </li>
                            <li>
                                <a href="/" onClick={this.onSignOut}>Logout</a>
                            </li>
                        </ul>
                    </nav>
                    <div className={`_dashboard-content ${this.state.menuOpen ? '_slide' : ''}`}>
                        <section className="_section _section-header">
                            <div className="_breadcrumbs">
                                {this.props.breadcrumbs}
                            </div>
                            <h1 className='_heading'>{this.props.heading}</h1>
                        </section>
                        <section className='_section _section-body'>
                            {this.props.children}
                        </section>
                    </div>                
                </div>

                
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: state.authState.token
    }
}

export default connect(mapStateToProps, {
    signOut
})(DashboardLayout)