import React from 'react'
import NavListItems from './NavListItems'

class Nav extends React.Component {

    render(){

        return(
            <nav className='_nav'>
                <ul className='_nav-list'>
                    <NavListItems />
                    <li className='_nav-list-item-menu'>
                        <button className={`_menu-button ${this.props.menuOpen ? '_open' : ''}`} onClick={this.props.onClick} aria-label="Menu">
                            <span />
                            <span />
                            <span />
                        </button>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default Nav