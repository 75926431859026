import React from 'react'
import DashboardLayout from './layout/DashboardLayout'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {
    fetchPartner,
    resetForms,
    updatePartner
} from '../../actions'
import Form from '../forms/PartnerEditForm'

class DashboardPartnerEdit extends React.Component {

    componentDidMount(){
        this.props.fetchPartner(this.props.token, this.props.match.params.partnerId)
    }

    componentWillUnmount(){
        this.props.resetForms()
    }

    onSubmit = formValues => {
        this.props.updatePartner(this.props.token, this.props.match.params.partnerId, formValues)
    }

    render(){

        if(this.props.data.success){
            return <Redirect to="/dashboard/partners" />
        }

        return(
            <DashboardLayout 
                heading="Edit Partner" 
                breadcrumbs={(<>
                    <Link to="/dashboard">Dashboard</Link> / <Link to="/dashboard/partners">Partners</Link> / {this.props.match.params.partnerId} / Edit
                </>)}
            >

                {
                    this.props.initialValues && 
                    <Form 
                        onSubmit={this.onSubmit} 
                        errors={this.props.data.errors ? this.props.data.errors : null} 
                        success={this.props.data.success ? this.props.data.success : null}
                        initialValues={this.props.initialValues}
                    />
                }
                
            </DashboardLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.authState.token,
        initialValues: state.partnersState[ownProps.match.params.partnerId],
        data: state.partnerUpdateState,
    }
}

export default connect(mapStateToProps, {
    fetchPartner,
    updatePartner,
    resetForms
})(DashboardPartnerEdit)