import React from 'react'
import {Field, reduxForm} from 'redux-form'
import {
    Input
} from './fields'

class categoryCreateForm extends React.Component {

    renderErrors = () => {
        return Object.values(this.props.errors).map((item, index) => {                
            return (
                <div className="_error-group" key={index}>
                    <div className="_error-message">{item[0]}</div>
                </div>
            )
        })
    }

    renderSuccess = () => {
        return (
            <div className="_success-group">
                <div className="_success-message">{this.props.success}</div>
            </div>
        )
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){

        return(
            <form className="_form _form-dashboard" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="_form-row">
                    <Field 
                        type="text"
                        name="name" 
                        label="Name" 
                        component={Input} 
                    />
                </div>

                <div className="_form-row">
                    <Field 
                        type="number"
                        name="order_number" 
                        label="Priority" 
                        component={Input} 
                    />
                </div>
                
                {this.props.errors && this.renderErrors()}
                {this.props.success && this.renderSuccess()}
                <div className="_form-row">
                    <button className="_button _button-2" >Create</button>
                </div>
            </form>
        )
    }
}

const validate = formValues => {
    
    const errors = {}

    if(!formValues.name){
        errors.name = "you must enter a name"
    }
    
    return errors
}

export default reduxForm({
    form: 'categorycreate',
    validate
})(categoryCreateForm)