import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/img/lacunza-logo.svg'

class Logo extends React.Component {

    render(){

        return(
            <Link to="/" className='_logo'>
                <img src={logo} alt="LACUNZA" />
            </Link>
        )
    }
}

export default Logo