import React from 'react'
import {Link} from 'react-router-dom'
import logo from '../../../assets/img/lacunza-logo.svg'

class Logo extends React.Component {

    render(){

        return(
            <Link to="/dashboard" className='_logo'>
                <img src={logo} alt="LACUNZA" width="170" height="83" />
            </Link>
        )
    }
}

export default Logo