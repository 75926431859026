export const RESET_FORMS = 'RESET_FORMS'
export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE'
export const SEND_CONTACT_MESSAGE_FAILED = 'SEND_CONTACT_MESSAGE_FAILED'

export const SIGNIN = 'SIGNIN'
export const SIGNIN_FAILED = 'SIGNIN_FAILED'
export const SIGNOUT = 'SIGNOUT'

export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS'
export const UPDATE_ACCOUNT_DETAILS_FAILES = 'UPDATE_ACCOUNT_DETAILS_FAILES'

export const FETCH_USERS = 'FETCH_USERS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const FETCH_USER = 'FETCH_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const DELETE_USER = 'DELETE_USER'

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_CATEGORY_FAILED = 'UPDATE_CATEGORY_FAILED'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const RESTORE_CATEGORY = 'RESTORE_CATEGORY'
export const FETCH_CATEGORIES_BIN = 'FETCH_CATEGORIES_BIN'
export const FORCE_DELETE_CATEGORY = 'FORCE_DELETE_CATEGORIES'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const RESTORE_PRODUCT = 'RESTORE_PRODUCT'
export const FETCH_PRODUCTS_BIN = 'FETCH_PRODUCTS_BIN'
export const FORCE_DELETE_PRODUCT = 'FORCE_DELETE_PRODUCTS'

export const FETCH_IMAGES = 'FETCH_IMAGES'
export const CREATE_IMAGE = 'CREATE_IMAGE'
export const CREATE_IMAGE_FAILED = 'CREATE_IMAGE_FAILED'
export const FETCH_IMAGE = 'FETCH_IMAGE'
export const UPDATE_IMAGE = 'UPDATE_IMAGE'
export const UPDATE_IMAGE_FAILED = 'UPDATE_IMAGE_FAILED'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const RESTORE_IMAGE = 'RESTORE_IMAGE'
export const FETCH_IMAGES_BIN = 'FETCH_IMAGES_BIN'
export const FORCE_DELETE_IMAGE = 'FORCE_DELETE_IMAGES'

export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS'
export const CREATE_DOCUMENT = 'CREATE_DOCUMENT'
export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED'
export const FETCH_DOCUMENT = 'FETCH_DOCUMENT'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const UPDATE_DOCUMENT_FAILED = 'UPDATE_DOCUMENT_FAILED'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'
export const RESTORE_DOCUMENT = 'RESTORE_DOCUMENT'
export const FETCH_DOCUMENTS_BIN = 'FETCH_DOCUMENTS_BIN'
export const FORCE_DELETE_DOCUMENT = 'FORCE_DELETE_DOCUMENTS'

export const FETCH_PARTNERS = 'FETCH_PARTNERS'
export const CREATE_PARTNER = 'CREATE_PARTNER'
export const CREATE_PARTNER_FAILED = 'CREATE_PARTNER_FAILED'
export const FETCH_PARTNER = 'FETCH_PARTNER'
export const UPDATE_PARTNER = 'UPDATE_PARTNER'
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED'
export const DELETE_PARTNER = 'DELETE_PARTNER'
export const RESTORE_PARTNER = 'RESTORE_PARTNER'
export const FETCH_PARTNERS_BIN = 'FETCH_PARTNERS_BIN'
export const FORCE_DELETE_PARTNER = 'FORCE_DELETE_PARTNERS'

export const FETCH_PUBLIC_CATEGORIES = 'FETCH_PUBLIC_CATEGORIES'
export const FETCH_PUBLIC_PRODUCT = 'FETCH_PUBLIC_PRODUCT'
export const FETCH_PUBLIC_PARTNERS = 'FETCH_PUBLIC_PARTNERS'
export const FETCH_PUBLIC_PRODUCT_NAMES = 'FETCH_PUBLIC_PRODUCT_NAMES'

export const SUBMIT_DISTRIBUTOR_FORM = 'SUBMIT_DISTRIBUTOR_FORM'
export const SUBMIT_DISTRIBUTOR_FORM_FAILED = 'SUBMIT_DISTRIBUTOR_FORM_FAILED'