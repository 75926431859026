import {
    CREATE_DOCUMENT,
    CREATE_DOCUMENT_FAILED,
    RESET_FORMS
} from '../actions/types'

const initialState = {}

const createDocumentReducer = (state = initialState, action) => {
    switch(action.type){
        case CREATE_DOCUMENT:
            return {
                details: action.payload,
                success: 'Document created successfully',
            }
        case CREATE_DOCUMENT_FAILED:
            return {
                ...state,
                errors: action.payload,
                success: null
            }
        case RESET_FORMS:
            return initialState
        default:
            return state
    }
}

export default createDocumentReducer