import _ from 'lodash'
import {
    FETCH_DOCUMENTS,
    CREATE_DOCUMENT,
    FETCH_DOCUMENT,
    UPDATE_DOCUMENT,
    DELETE_DOCUMENT,
    RESTORE_DOCUMENT
} from '../actions/types'

const initialState = {}

const documentsReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_DOCUMENTS:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }
        case CREATE_DOCUMENT:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }
        case FETCH_DOCUMENT:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }
        case UPDATE_DOCUMENT:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            }   
        case RESTORE_DOCUMENT:
            return {
                ...state, [action.productId]: {[action.payload.id]: action.payload}
            } 
        case DELETE_DOCUMENT:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.id)
            }
        default:
            return state
    }
}

export default documentsReducer