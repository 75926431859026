import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import contactReducer from './contactReducer'
import authReducer from './authReducer'
import categoriesReducer from './categoriesReducer'
import categoriesBinReducer from './categoriesBinReducer'
import categoryCreateReducer from './categoryCreateReducer'
import categoryUpdateReducer from './categoryUpdateReducer'
import productsReducer from './productsReducer'
import productsBinReducer from './productsBinReducer'
import productCreateReducer from './productCreateReducer'
import productUpdateReducer from './productUpdateReducer'
import imagesReducer from './imagesReducer'
import imagesBinReducer from './imagesBinReducer'
import imageCreateReducer from './imageCreateReducer'
import imageUpdateReducer from './imageUpdateReducer'
import documentsReducer from './documentsReducer'
import documentsBinReducer from './documentsBinReducer'
import documentCreateReducer from './documentCreateReducer'
import documentUpdateReducer from './documentUpdateReducer'
import partnersReducer from './partnersReducer'
import partnersBinReducer from './partnersBinReducer'
import partnerCreateReducer from './partnerCreateReducer'
import partnerUpdateReducer from './partnerUpdateReducer'
import publicCategoriesReducer from './publicCategoriesReducer'
import publicProductReducer from './publicProductReducer'
import publicPartnersReducer from './publicPartnersReducer'
import publicProductNamesReducer from './publicProductNamesReducer'
import distributorReducer from './distributorReducer'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authState']
}

const rootReducer = combineReducers({
    contactSatate: contactReducer,
    authState: authReducer,
    categoriesState: categoriesReducer,
    categoriesBinState: categoriesBinReducer,
    categoryCreateState: categoryCreateReducer,
    categoryUpdateState: categoryUpdateReducer,
    productsState: productsReducer,
    productsBinState: productsBinReducer,
    productCreateState: productCreateReducer,
    productUpdateState: productUpdateReducer,
    imagesState: imagesReducer,
    imagesBinState: imagesBinReducer,
    imageCreateState: imageCreateReducer,
    imageUpdateState: imageUpdateReducer,
    documentsState: documentsReducer,
    documentsBinState: documentsBinReducer,
    documentCreateState: documentCreateReducer,
    documentUpdateState: documentUpdateReducer,
    partnersState: partnersReducer,
    partnersBinState: partnersBinReducer,
    partnerCreateState: partnerCreateReducer,
    partnerUpdateState: partnerUpdateReducer,
    publicCategoriesState: publicCategoriesReducer,
    publicProductState: publicProductReducer,
    publicPartnersState: publicPartnersReducer,
    publicProductNamesState: publicProductNamesReducer,
    distributorState: distributorReducer,
    form: formReducer
})

export default persistReducer(persistConfig, rootReducer)