import React from 'react'
import Layout from './layout/Layout'
import HeadingSection from './views/HeadingSection'
import banner from '../assets/img/contact-us/contact-us-banner.jpg'
import ScrollToTop from '../ScrollToTop'
import Helmet from 'react-helmet'
import AuthForm from './forms/AuthForm'
import {
    signIn
} from '../actions'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

class Signin extends React.Component {

    onSubmit = formValues => {
        this.props.signIn(formValues)
    }

    render(){

        if(this.props.auth.token){
            return <Redirect to="/dashboard" />
        }

        return(
            <>
            <Helmet>
                <title>Admin Sign In | Lacunza Natural Comfort | Australia</title>
                <meta name="robots" content="noindex,nofollow" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <ScrollToTop />
            <Layout>
                <HeadingSection 
                    banner={banner}
                    heading="Admin Dashboard"
                />
                <section className='_section _section-auth'>
                    <div className='_grid'>
                        <div className='_column' style={{textAlign: 'center'}}>   
                            <div className='_panel'>
                                <h2>Sign In</h2>
                                <p>Log in here to sign into the Admin Dashboard or CMS.</p> 
                                <AuthForm 
                                    onSubmit={this.onSubmit} 
                                    errors={this.props.auth.errors ? this.props.auth.errors : null}
                                />
                            </div>
                        </div>    
                    </div>    
                </section>
            </Layout>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth: state.authState
    }
}

export default connect(mapStateToProps, {
    signIn
})(Signin)