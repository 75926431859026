import _ from 'lodash'
import {
    FETCH_DOCUMENTS_BIN,
    RESTORE_DOCUMENT,
    FORCE_DELETE_DOCUMENT
} from '../actions/types'

const initialState = {}

const documentsBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_DOCUMENTS_BIN:
            return {
                ...state, [action.productId]: {..._.mapKeys(action.payload, 'id')}
            }  
        case RESTORE_DOCUMENT:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.payload.id)
            }
        case FORCE_DELETE_DOCUMENT:
            return {
                ...state, [action.productId]: _.omit(state[action.productId], action.id)
            }
        default:
            return state
    }
}

export default documentsBinReducer