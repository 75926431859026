import React from 'react'
import banner from '../../assets/img/products/products-banner.jpg'

class HeadingSection extends React.Component {

    render(){

        return(
            <section className='_section _section-heading' style={{
                backgroundImage: `url(${this.props.banner ? this.props.banner : banner})`
            }}>
                <div className='_panel'>
                    <div className='_grid'>
                        <div className='_column'>
                            {this.props.heading && <h1>{this.props.heading}</h1>}    
                        </div>
                    </div>    
                </div>
            </section>
        )
    }
}

export default HeadingSection