import _ from 'lodash'
import {
    FETCH_CATEGORIES_BIN,
    RESTORE_CATEGORY,
    FORCE_DELETE_CATEGORY
} from '../actions/types'

const initialState = {}

const categoriesBinReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_CATEGORIES_BIN:
            return {
                ...state, ..._.mapKeys(action.payload, 'id')
            }  
        case RESTORE_CATEGORY:
            return _.omit(state, action.payload.id)
        case FORCE_DELETE_CATEGORY:
            return _.omit(state, action.payload)
        default:
            return state
    }
}

export default categoriesBinReducer